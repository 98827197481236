.stage2_nodes {
  overflow-y: scroll;
}

.stage2_nodes::-webkit-scrollbar {
  height:  6px;
}
.stage2_nodes::-webkit-scrollbar-track {
  background-color: #fafafafa;
}
.stage2_nodes::-webkit-scrollbar-thumb {
  background-color: #11256D;
}

.stage2_nodes::-webkit-scrollbar-thumb:hover {
  background-color: #294cca;
}