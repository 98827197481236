.container {
  width: 100%;
  padding: 20px;
  display: flex;
}
.tabsContainer {
  width: 25%;
  transition: 1s ease;
  /* border:1px solid black; */
}
.content {
  width: 75%;
  margin-top: 10px;
  margin-left: 50px;
}
.tabButton {
  background: #fff;
  width: 90%;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.25);
  border-radius: 4px;
  padding: 15px 15px;
  margin: 10px;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #7a7a7a;
  cursor: pointer;
}
.tabButtonActive {
  display: flex;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.25);
  border-radius: 4px;
  padding: 15px 15px;
  margin: 10px;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #7a7a7a;
  width: 100%;
  background-color: #11256d;
  color: #fff;
  cursor: pointer;
}
.tabButton img,
.tabButtonActive img {
  margin: 0 10px;
}
.right_section_container {
  border-radius: 4px;
  height: 225px;
  width: 73%;
  background: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
}

.right_section_container_heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  /* padding: 10px; */
}

.edit_title {
  flex-grow: 1;
  margin-right: 20px;
  outline: none;
  border: none;
  font-size: 18px;
}

.submit_btn {
  border-radius: 4px;
  color: #9a9a9a;
  border: none;
  cursor: pointer;
  padding: 6px 15px;
  margin-bottom: 15px;
}

.para_text_area {
  width: 100%;
  height: 80%;
  border: none;
  resize: none;
  padding: 20px 0;
  outline: none;
}
