@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
.root {
  width: 100%;
}
.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  border-bottom: 1px solid #eaeaea;
}
.practicearea {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.practicearea img {
  margin-right: 20px;
}
.paText {
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
}
.subissueText {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #11256d;
}
.accuracyScore {
  width: auto;
  text-align: right;
  font-family: Roboto;
}
.feedbacktext {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 20px;
  color: #7a7a7a;
  border-bottom: 1px solid #eaeaea;
}
