@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");

.validate_annotations_cards_container .slick-track {
  margin-left: 0 !important;
}
.dashboard_v2_steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #f7f7f7 !important;
  position: relative;
  top: 1px;
}
.dashboard_v2_steps .ant-steps-item-tail {
  width: 160% !important;
  margin: 0 0 0 5px !important;
  height: 1px !important;
}

.dashboard_v2_steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #11256d !important;
}

.dashboard_v2_steps .ant-steps-item-content {
  width: 0px !important;
}

.dashboard_v2_steps .ant-steps-item-icon {
  position: relative;
  right: -15px;
  width: 10px !important;
  height: 10px !important;
  margin: 0 !important;
}

.dashboard_v2_steps .ant-steps-icon-dot {
  background: #11256d !important;
  width: 7px;
  height: 7px;
}
.custom_scroll {
  overflow-y: scroll;
  height: 400px;
}
.custom_scroll-x {
  overflow-x: auto;
}

.custom_scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.custom_scroll::-webkit-scrollbar-track {
  background-color: #fafafafa;
}
.custom_scroll::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.custom_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #294cca;
}
.custom_scroll-x::-webkit-scrollbar {
  height: 2px;
}
.custom_scroll-x::-webkit-scrollbar-track {
  background-color: #fafafafa;
}
.custom_scroll-x::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.custom_scroll-x::-webkit-scrollbar-thumb:hover {
  background-color: #294cca;
}

.CenterAlign {
  top: 25%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -25%);
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 5;
}

.Overlay.Show {
  display: block;
}

.Modal {
  position: fixed;
  bottom: -150vh;
  width: 100%;
  left: 0;
  padding: 0 2rem 0 5rem;
  transition: all 0.8s ease-out;
  z-index: 10;
  overflow-y: scroll;
  max-height: 90vh;
}
.Modal::-webkit-scrollbar {
  width: 0px;
}
.Main {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  background-color: #ededed;
}

.Modal.Show {
  bottom: 0;
}

.Close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: transparent;
  border: 0;
  font-size: 18px;
}
.tooltip {
  position: relative;
}
.tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@layer base {
  :root {
    --color-text-green: #11256d;
    --color-text-grey: #c4c4c4;
    --color-text-darkGrey: #7a7a7a;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
