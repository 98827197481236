.card_container_wrapper {
  display: flex;
  align-items: flex-end;
  border-radius: 4px 10px 10px 4px;
  margin-bottom: 9px;
  margin-right: 10px;
  cursor: pointer;
}
.cards_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.project_card {
  display: flex;
  background-color: #ffffff;
  padding: 13px 24px;
}
.left_part {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.avatars {
  display: inline-flex;
  padding: 2px;
}

.avatar {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar:not(:last-child) {
  margin-right: -8px;
}

.avatar img {
  width: 24px;
  height: 24px;
}

.project_name {
  width: fit-content;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
}
.company_name {
  font-size: 12px;
  color: #11256d;
  margin-bottom: 12px;
}
.right_part {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 40%;
  font-size: 9px;
  color: #9a9a9a;
}
.steps {
  margin-bottom: 10px;
  margin-right: 15px;
}
.date_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/* .dropdown_container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
    top:0px;
} */
