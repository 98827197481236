.custom_scroll {
  overflow-y: scroll;
  height: 400px;
}

.custom_scroll::-webkit-scrollbar {
  width: 6px;
}
.custom_scroll::-webkit-scrollbar-track {
  background-color: #fafafafa;
}
.custom_scroll::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.custom_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #294cca;
}

.para_scroll {
  overflow-y: scroll;
  height: 150vh;
}

.para_scroll::-webkit-scrollbar {
  width: 6px;
}
.para_scroll::-webkit-scrollbar-track {
  background-color: #fafafafa;
}
.para_scroll::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.para_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #294cca;
}
.right_section_container {
  border-radius: 4px;
  height: 225px;
  width: 73%;
  background: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
}

.right_section_container_heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  /* padding: 10px; */
}

.edit_title {
  flex-grow: 1;
  margin-right: 20px;
  outline: none;
  border: none;
  font-size: 18px;
}

.submit_btn {
  border-radius: 4px;
  color: #9a9a9a;
  border: none;
  cursor: pointer;
  padding: 6px 15px;
  margin-bottom: 15px;
}

.para_text_area {
  width: 100%;
  height: 80%;
  border: none;
  resize: none;
  padding: 20px 0;
  outline: none;
}
